// header style hear

header.header-one {
  background: #f2f2f2;
  position: relative;
  .header--one-main {
    position: relative;
    .logo-area {
      padding: 40px 10px 40px 0;
      display: block;
    }
    .header-right-area {
      display: flex;
      justify-content: flex-end;
      .single-info-contact {
        display: flex;
        align-items: center;
        i {
          width: 57px;
          height: 57px;
          border-radius: 50%;
          background: #fff;
          box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.03);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          color: var(--color-primary);
        }
        .inner-content {
          margin-left: 20px;
          span {
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #74787c;
          }
          .title {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            margin-top: 6px;
            transition: 0.3s;
            min-width: max-content;
            @media #{$smlg-device} {
              font-size: 15px;
            }
            &:hover {
              color: var(--color-primary);
            }
          }
        }
        &.map {
          margin-left: 40px;
          padding-left: 40px;
          border-left: 1px solid #d9d9d9;
          @media #{$smlg-device} {
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid #d9d9d9;
          }
        }
      }

      .btn-area-header {
        display: flex;
        align-items: center;
        margin-left: 60px;
        @media #{$smlg-device} {
          margin-left: 20px;
        }
      }
    }
  }
}

.header-top-one {
  @media #{$md-layout} {
    display: none;
  }
  @media #{$sm-layout} {
    display: none;
  }
}

.logo-md-sm-device {
  display: none;
  @media #{$sm-layout} {
    display: block;
  }
  .logo {
    img {
      max-width: 120px;
    }
  }
}

.header-two-solari {
  &.header-top {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-primary);
    @media #{$smlg-device} {
      display: none;
    }
    .header-top-m {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--color-primary);
      .left {
        display: flex;
        align-items: center;
        gap: 36px;
        .inf {
          display: flex;
          align-items: center;
          gap: 10px;
          i {
            color: #fff;
          }
          p {
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
          a {
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      .right {
      }
    }
  }
}

.social-header-top-h2 {
  display: flex;
  align-items: center;
  span {
    color: #fff;
  }
  ul {
    padding: 0;
    margin: 0;

    margin-left: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    list-style: none;
    li {
      margin: 0;
      a {
        margin: 0;
        i {
          color: #fff;
          transition: 0.3s;
        }
        &:hover {
          i {
            color: #1f1f25;
          }
        }
      }
    }
  }
}

.main-haeder-wrapper-h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$large-mobile} {
    padding: 0 10px;
  }
  a.logo-area {
    padding: 23px 0;
    display: block;
    @media #{$sm-layout} {
      padding: 10px 0;
    }
    img {
      @media #{$md-layout} {
        max-width: 140px;
      }
      @media #{$sm-layout} {
        max-width: 120px;
      }
    }
  }
  .actions-area {
    display: flex;
    align-items: center;
    a.rts-btn {
      padding: 13px 25px;
      margin-left: 30px;
      @media #{$smlg-device} {
        display: none;
      }
    }
    .menu-btn {
      cursor: pointer;
      margin-left: 30px;
    }
    .search-btn {
      cursor: pointer;
      i {
        color: #000;
      }
    }
    .cart {
      margin-left: 30px;
      i {
        color: var(--color-primary);
        font-size: 22px;
        position: relative;
        margin-top: 8px !important;
        cursor: pointer;
        &::after {
          position: absolute;
          content: "2";
          right: -8px;
          bottom: 14px;
          height: 20px;
          width: 20px;
          background: var(--color-primary);
          border-radius: 50%;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
  }
}

#menu-btn {
  cursor: pointer;
}

header.header-three {
  padding: 32px 80px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  @media #{$smlg-device} {
    padding: 32px 20px;
  }
  .header-left {
    display: flex;
    align-items: center;
    gap: 50px;
    .logo-area {
      img {
        @media #{$sm-layout} {
          max-width: 120px;
        }
      }
    }
  }
  .header-right {
    .action-button-menu {
      display: flex;
      align-items: center;
      gap: 30px;
      a.contact {
        cursor: pointer;
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        transition: 0.3s;
        &:hover {
          color: var(--color-primary);
        }
      }
      #menu-btn {
        svg {
          rect {
            transition: 0.3s;
          }
        }
        &:hover {
          svg {
            rect {
              fill: var(--color-primary);
            }
          }
        }
      }
    }
  }
  .logo-area {
    .logo-white {
      display: block;
    }
    .logo-dark {
      display: none;
    }
  }
  &.sticky {
    .logo-area {
      .logo-white {
        display: none;
      }
      .logo-dark {
        display: block;
      }
    }
    .main-nav-one ul li a.nav-link {
      color: #1f1f25;
    }
  }
}

header.header-three.header--sticky.sticky
  .header-right
  .action-button-menu
  #menu-btn
  svg
  rect {
  fill: var(--color-primary);
}
header.header-three.sticky .header-right .action-button-menu a.contact {
  color: var(--color-primary);
}

.onepage {
  .main-nav-one ul li a.nav-link {
    margin: 0 10px;
  }
}

.index-two.onepage {
  input {
    border-radius: 5px;
  }
  textarea {
    border-radius: 5px;
  }
}

.header-three.sticky {
  a.logo-area {
    padding: 15px 0;
  }
}

header.header-four {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 5;
  padding: 20px 80px;
  @media #{$smlg-device} {
    padding: 20px 40px;
  }
  @media #{$sm-layout} {
    padding: 20px;
  }
  .header-left {
    .logo-area {
      img {
        @media #{$md-layout} {
          max-width: 120px;
        }
        @media #{$sm-layout} {
          max-width: 120px;
        }
      }
    }
  }
  .nav-area {
    .main-nav-one ul li.has-dropdown a.nav-link {
      color: #fff;
    }
    .main-nav-one ul li {
      a.nav-link {
        color: #fff;
      }
    }
  }
  .header-right {
    .action-button-menu {
      display: flex;
      align-items: center;
      .contact {
        color: #fff;
      }
      #menu-btn {
        margin-left: 25px;
      }
    }
  }
}

.about-area-solari-3-right {
  .solari-title-area-four {
    text-align: left;
    .pre-title {
      text-align: left;
      margin-right: auto;
      margin-left: 0;
      position: relative;
    }
  }
}

.solari-title-area-four span.pre-title::after {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background-image: url(../images/banner/shape/03.png);
  height: 7px;
  width: 48px;
  background-size: contain;
  background-repeat: no-repeat;
}

.solari-title-area-four span.pre-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--color-primary);
  display: block;
  margin-bottom: 16px;
  padding-left: 60px;
  position: relative;
  max-width: max-content;
  margin-bottom: 16px;
}
.solari-title-area-four .pre-title {
  text-align: left;
  margin-right: auto;
  margin-left: 0;
}
.solari-title-area-four .title {
  @media #{$smlg-device} {
    br {
      display: none;
    }
  }
}

.header-four.header--sticky.sticky {
  background: #1b1b1b;
}

.draw {
  position: relative;
  svg {
    position: absolute;
    left: 84px;
    bottom: -2px;
    @media #{$large-mobile} {
      position: absolute;
      left: 0;
      bottom: -13px;
    }
  }
  path {
    animation: progress-draw 5s linear forwards;
  }
  @keyframes progress-draw {
    from {
      stroke-dasharray: 0 1000;
    }
    to {
      stroke-dasharray: 1000;
    }
  }
}

.title-shop-main-center {
  .title {
    .draw {
      svg {
        left: 40px;
        bottom: -4px;
        @media #{$small-mobile} {
          left: -46px;
          bottom: -17px;
        }
      }
    }
  }
}

.single-shopping-product {
  &:hover {
    .invisible-btn {
      transform: translateX(-50%) scale(1);
    }
  }
}

.banner-area-four-wrapper-swiper-main {
  position: relative;
  .circle-animation {
    position: absolute;
    right: 80px;
    bottom: 100px;
    z-index: 10;
    border-radius: 50%;
    border: 1px solid #74787c;
    @media #{$large-mobile} {
      display: none;
    }
    i {
      position: absolute;
      color: #fff;
      left: 42%;
      top: 42%;
      font-size: 24px;
    }
  }
  .uni-circle-text::before {
    content: "";
    display: block;
    width: 78px;
    height: 78px;
    border: 1px solid rgba(245, 245, 245, 0);
    position: absolute;
    transform: scale(0.5);
    left: 0;
    top: 0;
    transform: translate(20px, 20px);
    border-radius: 100%;
  }
  .uni-circle-text-path {
    fill: #fff;
    height: auto;
    max-width: 132px;
    right: 10%;
    transform-origin: center;
    text-transform: uppercase;
  }
  .uni-animation-spin {
    display: inline-flex;
    animation: spin 10s linear 0s infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.onepage {
  .main-nav-one ul li.has-dropdown a::before {
    display: none;
  }
}
.rts-header {
  &.header-style-three {
    .container-fluid {
      padding-left: 80px;
      padding-right: 100px;
      @media screen and (max-width: 1400px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    .header__menu {
      display: flex;
      align-items: center;
      @media screen and (max-width: 991px) {
        padding: 15px 5px;
      }
      .header__logo{
        a{
          img{
            min-width: 130px;
          }
        }
      }
      &__wrapper {
        width: 100%;
        margin-left: 45px;
        border-left: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        @media screen and (max-width: 1200px) {
          border-right-color: transparent;
          border-left-color: transparent;
        }
        &__top {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #eeeeee;
          padding: 25px 0;
          @media screen and (max-width: 1200px) {
            display: none;
          }
          .header__top__right {
            display: flex;
            margin-right: 80px;
            font-weight: 600;
            gap: 50px;
            @media screen and (max-width: 1500px) {
              margin-right: 60px;
            }
            @media screen and (max-width: 1240px) {
              margin-right: 30px;
            }
            color: var(--color-heading-1);
            .location {
              display: flex;
              gap: 10px;
            }
            .email {
              display: flex;
              gap: 10px;
              position: relative;
              &::before{
                position: absolute;
                left: -25px;
                height: 6px;
                width: 6px;
                top: 50%;
                transform: translateY(-50%);
                background: var(--color-heading-1);
                content: '';
              }
            }
          }
          .header__top__left {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-left: 30px;
            .header__left__text {
              color: var(--color-heading-1);
              font-weight: 600;
            }
          }
        }
        &__bottom {
          display: flex;
          justify-content: space-between;
          padding: 6px 0;
          .header__menu__left {
            margin-left: 20px;
          }
          .header__menu__right {
            display: flex;
            gap: 40px;
            align-items: center;
            margin-right: 80px;
            @media screen and (max-width: 1500px) {
              margin-right: 60px;
            }
            @media screen and (max-width: 1240px) {
              margin-right: 30px;
            }
            @media screen and (max-width: 1200px) {
              margin-right: 60px;
            }
            @media screen and (max-width: 991px) {
              display: block;
            }
            @media screen and (max-width: 575px) {
              display: none;
            }

            .btn-section {
              border-right: 1px solid #eeeeee;
              display: flex;
              gap: 30px;
              align-items: center;
              padding-right: 40px;
              @media screen and (max-width: 1240px) {
                padding-right: 0;
                border-right: unset;
              }
              .cart-btn {
                position: relative;
                span {
                  display: grid;
                  place-items: center;
                  font-size: 12px;
                  height: 20px;
                  min-width: 20px;
                  border-radius: 50%;
                  background: var(--color-primary);
                  color: var(--color-white);
                  position: absolute;
                  bottom: -10px;
                  left: 10px;
                }
              }
            }
            .quote-section {
              @media screen and (max-width: 1200px) {
                display: none;
              }
              a {
                color: var(--color-heading-1);
                font-weight: 600;
                text-transform: uppercase;
                transition: all 0.3s ease-in-out;
                &:hover {
                  color: var(--color-primary);
                }
                i {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
      .header__offcanvase {
        position: relative;
        left: -25px;
        @media screen and (max-width: 991px) {
          left: 0;
        }
        &__btn {
          height: 55px;
          width: 55px;
          background: var(--color-primary);
          color: var(--color-white);
          font-size: 18px;
        }
      }
    }
    .main-nav-one ul li.has-dropdown a::before {
      display: none;
    }
  }
  &.black-version, 
  &.black-version.header--sticky{
    background: #1F1F25;
    .header__menu__wrapper{
        border-left-color: #32323C;
        border-right-color: #32323C;
        @media screen and (max-width: 1200px) {
          border-left-color: transparent;
          border-right-color: transparent;
        }
    }
    .header__menu__wrapper__top{
        border-color: #32323C;
        @media screen and (max-width: 1200px) {
          display: none;
        }
    }
    .header__top__left, 
    .header__top__right{
        color: var(--color-white);
        span{
            color: var(--color-white) !important;
        }
        i{
            color: var(--color-white) !important;
        }
        a{
            color: var(--color-white);
        }
    }
    .header__menu__left{
        ul li a{
            color: var(--color-white) !important;
        }
        .has-dropdown ul.submenu li a{
            color: #32323C !important;
        }
    }
    .btn-section{
        i{
            color: var(--color-white);
        }
    }
    .quote-section{
        a{
            color: var(--color-white) !important;
        }
    }
  }
  &.header-style-nine{
    .header__top{
      background: var(--color-primary-2);
      padding: 12px 0;
      @media screen and (max-width: 991px) {
        display: none;
      }
      &__content{
        display: flex;
        justify-content: space-between;
      }
      &--left{
        p{
          display: flex;
          gap: 10px;
          color: var(--color-white);
        }
      }
      &__right{
        display: flex;
        gap: 55px;
        .location, .email{
          display: flex;
          gap: 10px;
          color: var(--color-white);
          i{
            color: var(--color-white);
          }
        }
      }
    }
    .header__bottom{
      padding: 13px 0;
      &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header__menu__right {
          display: flex;
          gap: 35px;
          align-items: center;
          .btn-section {
            display: flex;
            gap: 30px;
            align-items: center;
            .cart-btn {
              position: relative;
              span {
                display: grid;
                place-items: center;
                font-size: 12px;
                height: 20px;
                min-width: 20px;
                border-radius: 50%;
                background: var(--color-primary-2);
                color: var(--color-white);
                position: absolute;
                bottom: -10px;
                left: 10px;
              }
            }
          }
          .quote-section {
            @media screen and (max-width: 767px) {
              display: none;
            }
            a {
              color: var(--color-heading-1);
              font-weight: 600;
              text-transform: uppercase;
              transition: all 0.3s ease-in-out;
              &:hover {
                color: var(--color-primary-2);
              }
              i {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    .main-nav-one ul li.has-dropdown a::before {
      display: none;
    }
    .main-nav-one ul li:hover a.nav-link {
      color: var(--color-primary-2);
    }
    ul li a:hover {
      color: var(--color-primary-2);
    }
    .header__offcanvase {
      position: relative;
      left: 0;
      display: none;
      @media screen and (max-width: 991px) {
        display: block;
        
      }
      @media screen and (max-width: 991px) {
        left: 0;
      }
      &__btn {
        height: 55px;
        width: 55px;
        background: var(--color-primary-2);
        color: var(--color-white);
        font-size: 18px;
      }
    }
  }
}
.black-version.header--sticky, .header-style-three.header--sticky{
  padding: 0 !important;
}
