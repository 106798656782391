// testimonials style one
.bg-testimonials-1{
    background-image: url(../images/testimonials/01.jpg);
}

.rts-single-testimonials-one{
    padding: 40px;
    background: #FFFFFF;
    @media #{$large-mobile} {
        padding: 15px;
    }
    p.disc{
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        margin-top: 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid #F1F1F1;
        margin-bottom: 30px;
    }
    .awener-area{
        display: flex;
        align-items: center;
        a{
            margin-right: 12px;
            display: block;
        }
        .main{
            .title{
                margin-bottom: 3px;
                transition: .3s;
                &:hover{
                    color: var(--color-primary);
                }
            }
            span{
                color: var(--color-primary);
            }
        }
    }
}
.flot-wrapper{
    cursor: url(../images/project/shape/03.png), auto;
    .single-project-solari-h3{
        cursor: url(../images/project/shape/03.png), auto;
        .thumbnail{
            cursor: url(../images/project/shape/03.png), auto;
        }
    }
}
.faq-section-start-solar{
    .stok-bg.images-r.left{
        left: 5%;
    }
}
.stok-bg.images-r.left{
    text-align: left;
    left: 11%;
}
.bg-tst-ac{
    background-image: url(../images/about/05.jpg);
}
.rts-service-area-height-solari{
    .stok-bg{
        z-index: 0;
    }
    .stok-bg.images-r.left{
        text-align: left;
        left: 2%;
    }
}

.rts-case-studyes{
    .stok-bg{
        z-index: 0;
    }
}


.air-condition-swiper-main-wrapper{
    margin-top: 50px;
    overflow: hidden;
    .single-test{
        padding: 32px 40px ;
        background: #fff;
        border-radius: 10px;
        .profile-area{
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #EFEFEF;
            img{
                margin-right: 20px;
            }
            .admin{
                .title{
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 26px;
                    margin-bottom: 0px;
                }
                span{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
        p.disc{
            color: #171C26;
        }
    }
}

.thumbnail-img-testimonials-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .thumbnail{
        margin-top: 0;
        img{
            border: 10px solid #fff;
            border-radius: 10px;
        }
    }
    .inner-content{
        position: absolute;
        width: 306px;
        height: 275px;
        background: #171C26;
        border-radius: 15px;
        padding: 25px;
        text-align: center;
        left: 290px;
        top: 30%;    
        .thumbnail{
            margin-top: 15px;
            border: none;
            img{
                border: none;
                border-radius: 0;
            }
        }
        p.disc{
            font-weight: 500;
            margin-top: 30px;
            font-size: 22px;
            line-height: 30px;
            font-family: var(--font-primary);
            color: #fff;
            margin-bottom: 0;
        }
    }
}



.rts-feedback-area-solar-energy{
    background: #1B1B1B;
    p.pre{
        color: var(--color-primary);
    }
    .title{
        color: #fff;
    }
}



.rts-single-feedback-solar-energy{
    display: flex;
    align-items: flex-start;
    gap: 30px;
    padding: 40px;
    background: #1F1F1F;
    border-radius: 15px;
    @media #{$smlg-device} {
        padding: 20px;
    }
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
    .client-image{
        width: max-content;
        img{
            max-width: max-content;
            padding: 7px;
            border: 2px solid var(--color-primary);
            border-radius: 50%;
        }
    }
    .content{
        p.para{
            color: #FFFFFF;
            font-style: italic;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #2C2C2C;
        }
        .cottom-review-area{
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
                margin-bottom: 0;
                color: #FFFFFF;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
            .stars{
                i{
                    color: var(--color-primary);
                }
            }
        }
    }
}


.mySwiper-testimonials-solari{
    overflow: hidden;
    max-width: 655px;
    margin: auto;
}

.testimonials-review-main-wrapper{
    text-align: center;
    .single-review-area-soalri{
        max-width: 820px;
        margin: auto;
        p.dsic{
            font-weight: 400;
            font-size: 22px;
            line-height: 32px;
        }
        .quote{
            margin-bottom: 25px;
        }
        p{
            .disc{
                font-weight: 400;
                font-size: 22px;
                line-height: 32px;
                color: #7E7E7E;
            }
        }
        .author-area{
            margin-top: 30px;
            .authoe{
                margin-bottom: 14px;
            }
            .title{
                margin-bottom: 2px;
            }
            span{
                letter-spacing: 0.1em;
                color: #7E7E7E;
            }
            .star-area{
                margin-top: 7px;
                i{
                    font-size: 16px;
                    color: var(--color-primary);
                }
            }
        }
    }
}

.rts-client-review-area-h2{
     position: relative;
     z-index: 10;
    .stok-bg{
        margin-left: -190px;
    }
    .shape-author-img{
        position: absolute;
        left: 20%;
        top: 200px;
        @media #{$sm-layout} {
            display: none;
        }
    }
    .shape-author-img-2{
        position: absolute;
        right: 20%;
        top: 200px;
        @media #{$sm-layout} {
            display: none;
        }
    }
}
.gsap-marker-end,
.gsap-marker-start{
    display: none !important;
}
.gsap-marker-scroller-end{
    display: none !important;
}
.gsap-marker-scroller-start{
    display: none !important;
}


.rts-solari-blog-area-start.rts-section-gap.reveal{
    .stok-bg{
        z-index: 0;
    }
}

.mySwiper-testimonials-solari{
    position: relative;
    padding-bottom: 93px;
    .swiper-pagination{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .swiper-pagination-bullet{
        background-color: #BBBBBB;
        opacity: 1;
    }
    .swiper-pagination-bullet-active{
        background-color: transparent;
        opacity: 1;
        background-image: url(../images/banner/shape/01.png);
        background-repeat: no-repeat;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 20px;
        width: 20px;
    }
}

.rts-testimonial{
    .swiper-pagination-10{
        max-width: max-content;
        margin-top: 60px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .swiper-pagination-bullet{
          background: transparent;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          border: 1px solid transparent;
          position: relative;
          transition: all .3s ease;
          opacity: 1;
          margin-right: 10px;
          &::before{
            position: absolute;
            content: '';
            height: 6px;
            width: 6px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            background: #BBBBBB;
            transform: translate(-50%, -50%);
          }
          &-active{
            border-color: var(--color-primary-2);
            &::before{
                background: var(--color-primary-2);
            }
          }
        }
      }
}
.testimonial__wrapper{
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    @media screen and (max-width: 991px) {
        flex-direction: column;
    }
    &--left{
        min-width: 544px;
        @media screen and (max-width: 991px) {
            width: 100%;
        }
        img{
            width: 100%;
        }
    }
    &--slider{
        background: var(--color-primary-2);
        padding: 60px;
        max-width: 100%;
        overflow: hidden;
        &__active{
            overflow: hidden;
        }
        .testimonial__single{
            &--icon{
                margin-bottom: 30px;
            }
            &--heading{
                font-weight: 600;
                color: var(--color-white);
            }
            &--description{
                color: var(--color-white);
                max-width: 625px;
                margin-bottom: 40px;
            }
            &--author{
                display: flex;
                gap: 20px;
                .author__image{
                    img{
                        border-radius: 50%;
                        min-height: 60px;
                        min-width: 60px;
                        height: 60px;
                        width: 60px;
                    }
                }
                .author__meta{
                    h6{
                        font-size: 20px;
                        color: #fff;
                        margin-bottom: 10px;

                    }
                    span{
                        color: var(--color-white);
                    }
                }
            }
        }
    }
}