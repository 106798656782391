
.rts-btn{
    padding: 19px 40px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    font-family: var(--font-secondary);
    border-radius: 100px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    min-width: max-content;
    &::before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        border-radius: 100px;
    }
    &:focus{
        border: none;
        border: none;
        box-shadow: none;
    }
    &:active{
        border: none;
        border: none;
        box-shadow: none;
        &:focus{
            box-shadow: none;
        }
    }
    &.btn-primary{
        color: #fff;
        background: #1f1f25;
        &::before{
            background: var(--color-primary);
        }
        &:hover{
            background: #1f1f25;
            color: #fff;
            &::before{
                background: var(--color-primary);
                transform: scaleX(0);
            }
        }
    }

    &.btn-border{
        border: 2px solid #FFFFFF;
        color: #ffff;
        color: #fff;
        background: #df2f03;
        z-index: 1;
        border: 1px solid rgba(31, 31, 37, 0.1);
        &::before{
            background: #fff;
            border-radius: 0;
            z-index: -1;
        }
        &:hover{
            background: #1f1f25;
            color: #fff !important;
            &::before{
                background: var(--color-primary);
                transform: scaleX(0);
            }
        }
        &.radious-0{
            border-radius: 0;
            border: 1px solid rgba(31, 31, 37, 0.1);
            color: #1F1F25;
            i{
                margin-left: 8px;
            }
        }
    }
    &.with-arrow{
        i{
            margin-left: 10px;
            margin-bottom: -2px;
        }
    }
    &.btn-three{
        border-radius: 0 !important;
        &::before{
            background: #FF7029 !important;
            border-radius: 0 !important;

        }
        &:hover::before{
            background: #ff7029 !important;
            border-radius: 0 !important;
        }
    }
}

.index-two{
    .rts-btn.btn-primary{
        border-radius: 4px;
        &::before{
            border-radius: 4px;
        }
    }
}


.button-circle-text{
    display: flex;
    align-items: center;
    gap: 15px;
    color:#1F1F25;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    transition: .3s;
    i{
        width: 50px;
        height: 50px;
        border: 1px solid #1F1F25;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
    }
    &:hover{
        i{
            background: var(--color-primary);
            border: 1px solid var(--color-primary);
            color: #fff;
        }
    }
}
.read-more-btn{
    display: flex;
    gap: 10px;
    align-items: center;
}