// projects area
.container-full {
  max-width: 1920px;
  max-width: 1920px;
  margin: auto;
}

.title--between-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$small-mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  .button-area {
    display: flex;
    align-items: center;
    margin-top: 75px;
    @media #{$small-mobile} {
      margin-top: 30px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      height: 80px;
      width: 80px;
      background: transparent;
      border-radius: 50%;
      background-image: none;
      transition: 0.3s;
      @media #{$small-mobile} {
        height: 60px;
        width: 60px;
      }
      &::after {
        color: #1f1f25 !important;
      }
      &:hover {
        background: var(--color-primary);
        &::after {
          color: #fff !important;
        }
      }
    }
    .swiper-button-next {
      position: relative;
      margin-left: auto;
      right: -70px;
      @media #{$small-mobile} {
        right: -212px;
      }
      &::after {
        position: absolute;
        content: "\f061";
        font-size: 22px;
        color: #fff;
        font-family: "FontAwesome";
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        transform: translateX(15px);
      }
    }
    .swiper-button-prev {
      position: relative;
      left: -110px;
      @media #{$small-mobile} {
        left: -33px;
      }
      &:hover {
        transform: translateX(-15px);
      }
      &::after {
        position: absolute;
        content: "\f060";
        font-size: 22px;
        color: #fff;
        font-family: "FontAwesome";
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.project-one-wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000000e3;
    z-index: 0;
    opacity: 0;
    transition: 0.6s;
    pointer-events: none;
  }
  &::before {
    position: absolute;
    content: "";
    bottom: -15px;
    left: 0;
    height: 10px;
    width: 100%;
    background: var(--color-primary);
    z-index: 0;
    z-index: 5;
    transition: 0.6s;
  }
  .shape {
    position: absolute;
    z-index: 5;
    top: -180px;
    right: -180px;
    transition: 0.6s;
    pointer-events: none;
  }
  .thumbnail {
    display: block;
    img {
      transition: 0.6s;
    }
  }
  .content {
    position: absolute;
    bottom: 50px;
    left: 40px;
    transition: 0.3s;
    z-index: 5;
    transform: scaleY(0);
    span {
      display: block;
      margin-bottom: 10px;
      color: #fff;
    }
    .title {
      margin-bottom: 30px;
      color: #fff;
      transition: 0.3s;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  &:hover {
    &::after {
      opacity: 1;
    }
    &::before {
      bottom: 0;
    }
    .shape {
      top: -90px;
      right: -100px;
    }
    .content {
      left: 40px;
      transform: scaleY(1);
    }
    .thumbnail {
      img {
        transform: scale(1.15);
      }
    }
  }
}

.project-one-wrapper {
  .thumbnail {
    img {
      width: 100%;
    }
  }
}

// projct-detals area start style

.portfolio-disc-content {
  margin-top: 50px;
  .title-area {
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--color-primary);
    }
    .title {
      margin-top: 15px;
      font-size: 30px;
    }
  }
  p.disc {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #74787c;
    margin-bottom: 25px;
  }
  p.quote {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #1f1f25;
  }
}

// product area
.big-bg-porduct-details {
  margin-top: 50px;
  border-radius: 7px;
  padding-left: 40px;
  @media #{$sm-layout} {
    padding-left: 0px;
  }
}

.big-bg-porduct-details .project-info {
  background: #f7f7f7;
  // box-shadow: 0px 10px 21px rgba(27, 18, 18, 0.05);
  border-radius: 0 0 0 0;
}

.big-bg-porduct-details .project-info .info-head {
  padding: 24px 40px;
  background: var(--color-primary);
  border-radius: 0 0 0 0;
  border-radius: 7px 7px 0 0;
}

.big-bg-porduct-details .project-info .info-head .title {
  color: #fff;
  margin-bottom: 0;
}

.big-bg-porduct-details .project-info .info-body {
  padding: 15px 40px;
  @media #{$sm-layout} {
    padding: 15px 20px;
  }
}

.big-bg-porduct-details .project-info .info-body .single-info {
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid #e9e9e9;
  &.last {
    border: none;
  }
}

.big-bg-porduct-details .project-info .info-body .single-info .info-details {
  margin-left: 20px;
}

.big-bg-porduct-details .project-info .info-body .single-info .info-ico i {
  height: 50px;
  width: 50px;
  background: #f9f0f0;
  color: var(--color-primary);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 6px 31px rgba(0, 0, 0, 0.05);
}

.big-bg-porduct-details
  .project-info
  .info-body
  .single-info
  .info-details
  span {
  margin-bottom: 5px;
}

.big-bg-porduct-details
  .project-info
  .info-body
  .single-info
  .info-details
  .name {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 3px;
}

.check-area-pd {
  margin-top: 30px;
  margin-bottom: 30px;
  .single {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    i {
      margin-top: 5px;
      margin-right: 10px;
      color: var(--color-primary);
    }
    p {
      margin-bottom: 0;
      color: #74787c;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
  }
}

#form-messages {
  &.success {
    color: var(--color-primary);
  }
  &.error {
    color: var(--color-danger);
  }
}

.air-condition-swiper-wrapper {
  position: relative;
  overflow: hidden;
  .swiper-wrapper {
    .swiper-slide {
      &.swiper-slide-active {
        .single-case-ac {
          .inner-content {
            bottom: 30px;
          }
        }
      }
      .single-case-ac {
        .inner-content {
          position: absolute;
          left: 30px !important;
          bottom: -20px;
          width: 87%;
          height: 97px;
          background: #ffffff;
          opacity: 0;
          transition: 0.6s;
          border-radius: 10px;
          .inner {
            position: absolute;
            position: absolute;
            left: 20px;
            top: 20px;
            span.pre {
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              color: #1f1f25;
              display: block;
              margin-bottom: 10px;
              position: relative;
              max-width: max-content;
              &::after {
                position: absolute;
                content: "";
                right: -15px;
                top: 40%;
                transform: translate(50%);
                height: 1px;
                width: 15px;
                background: #1f1f25;
              }
            }
            .title {
              font-weight: 700;
              font-size: 24px;
              line-height: 34px;
              color: #1f1f25;
              margin-bottom: 25px;
              transition: 0.3s;
              @media #{$small-mobile} {
                font-size: 17px;
              }
              &:hover {
                color: var(--color-primary);
              }
            }
          }
        }
      }
      &.swiper-slide-active {
        .inner-content {
          opacity: 1;
          left: 15px;
        }
      }
    }
  }
}

.single-cleaning-project {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 30px;
  gap: 30px;
  a.thumbnail {
    display: block;
    width: 100%;
    img {
      border-radius: 30px 0 0 30px;
      min-width: max-content;
      width: max-content;
    }
  }
  .project-content-area {
    padding: 35px;
    margin-left: 30px;
    .icon {
      height: 90px;
      width: 90px;
      background: #eff3ff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;
      svg {
        path {
          fill: var(--color-primary);
        }
      }
    }
    .title {
      margin-bottom: 10px;
      &:hover {
        color: var(--color-primary);
      }
    }
    p.disc {
      margin-bottom: 30px;
    }
  }
}

.cta-cleaning-p {
  padding: 80px;
  border-radius: 40px;
  background: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 120px;
  input {
    height: 60px;
    background: #ffffff;
    border-radius: 100px;
    padding: 5px 25px;
  }
  a.rts-btn.btn-primary {
    background: #fff;
    display: flex;
    align-items: center;
    i {
      margin-left: 5px;
      margin-top: 2px;
    }
    &::before {
      background: #1f1f25;
    }
    &:hover {
      color: #1f1f25;
    }
  }
}

.cta-main-wrapper-cleaning {
  background-image: url(../images/cta/04.jpg);
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px;
  border-radius: 40px;
  margin-top: -150px;
  .left-content {
    p.pre {
      color: #fff;
      margin-bottom: 10px;
    }
    .title {
      color: #fff;
      margin-bottom: 0;
    }
  }
}

.project-soalr-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media #{$large-mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
  .title {
    margin-bottom: 0;
  }
}

.container-120 {
  max-width: 1920px;
  padding: 0 120px;
  @media #{$md-layout} {
    padding: 0 65px;
  }
  @media #{$sm-layout} {
    padding: 0;
  }
}

.rts-solar-single-product-one {
  position: relative;
  overflow: hidden;
  .inner-content {
    padding: 27px 30px;
    background: #fff;
    border-radius: 5px 0 0 5px;
    position: absolute;
    bottom: 40px;
    right: 0;
    width: 92%;
    a {
      .title {
        margin-bottom: 7px;
        transition: 0.3s;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
}

.project-details-carousel-big {
  overflow: hidden;
  position: relative;
  .swiper-button-next,
  .swiper-button-prev {
    background-image: none;
    opacity: 0;
    transition: 0.3s;
    &::after {
      content: "\f061";
      font-family: "fontawesome";
      font-size: 22px;
      height: 60px;
      width: 60px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      transform: scale(1.2);
    }
  }
  .swiper-button-next {
    right: 80px;
    &::after {
      color: var(--color-primary);
    }
  }
  .swiper-button-prev {
    left: 60px;
    &::after {
      color: var(--color-primary);
      content: "\f060";
    }
  }
  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 1;
    }
  }
}

// gallery-image popup

#gallery {
  padding-top: 40px;
  @media screen and (min-width: 991px) {
    padding: 0;
  }
}

.img-wrapper {
  position: relative;
  margin-top: 15px;
  img {
    width: 100%;
  }
}

.img-overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  i {
    color: #fff;
    font-size: 3em;
  }
}

#overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  // Removes blue highlight
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  img {
    margin: 0;
    width: 80%;
    height: auto;
    object-fit: contain;
    padding: 5%;
    @media screen and (min-width: 768px) {
      width: 60%;
    }
    @media screen and (min-width: 1200px) {
      width: 50%;
    }
  }
}

#nextButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
}

#prevButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
}

#exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  position: absolute;
  top: 15px;
  right: 15px;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
}

.product-bg-h3 {
  background-image: url(../images/project/27.jpg);
}

.rts-product-area.product-bg-h3 {
  .solari-title-area-three {
    .title {
      color: #fff;
    }
    p.disc {
      color: #d9d9d9;
    }
  }
}

.flot-wrapper {
  width: 150%;
  overflow: hidden;
}

.single-progress-area-h4 {
  margin-top: 30px;
  &:first-child {
    margin-top: 0;
  }
  .page-wrapper {
    width: calc(100% - 100px);
    margin: 100px auto 200px;
  }
  .progress {
    height: 6px;
    position: relative;
    margin-top: 14px;
    border-radius: 4px;
    .bg--primary {
      background: var(--color-primary);
    }
  }
  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 4px;
  }
  .progress-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #1f1f25;
      margin-bottom: 0;
      font-family: var(--font-primary);
    }
    span {
      &.parcent {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #111a2e;
      }
    }
  }
}

.bg-yellow {
  background: #f26722;
}

.bg-dark {
  background: #1f1f25;
}

.rts-solar-supply-start {
  .solari-title-area-three {
    .title {
      font-size: 40px;
      line-height: 60px;
      @media #{$large-mobile} {
        font-size: 32px;
        line-height: 45px;
      }
      br {
        @media #{$smlg-device} {
          display: none;
        }
      }
    }
  }
}
.map-area-h3 img {
  width: 100%;
}

.swiper-h1-solari-main-wrapper {
  overflow: hidden;
  position: relative;
  padding-bottom: 50px;
  .rts-solar-single-product-one {
    &:hover {
      .thumbnail {
        img {
          transform: scale(1.2);
        }
      }
    }
    .thumbnail {
      overflow: hidden;
      display: block;
      border-radius: 10px;
      img {
        width: 100%;
        transition: 1s;
        display: block;
      }
    }
  }
  .swiper-pagination-bullet {
    background: var(--color-primary);
  }
}

.word-line {
  overflow: hidden;
}
.skew-up {
  opacity: 0;
}

.rts-solar-single-product-one {
  border-radius: 10px;
  .thumbnail {
    border-radius: 10px;
    display: block;
    img {
      transition: 0.3s;
    }
  }
  &:hover {
    .thumbnail {
      img {
        transform: scale(1.2);
      }
    }
  }
}

.single-case-ac {
  .thumbnail {
    display: block;
    overflow: hidden;
    border-radius: 10px;
    img {
      transition: 0.3s;
    }
    &:hover {
      img {
        transform: scale(1.09);
      }
    }
  }
}

.single-product-main-four {
  display: flex;
  align-items: center;
  gap: 130px;
  @media #{$md-layout} {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 80px;
    gap: 30px;
  }
  @media #{$sm-layout} {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 80px;
    gap: 30px;
  }
}

.bg-lighten {
  background: #f6f6f6;
}

.why-choose-us-section {
  .mission-accordion-area {
    margin-top: 0;
    width: auto;
    margin-left: 0;
  }
  .video-thumbnail-img {
    margin-left: 120px;
  }
}
.mission-accordion-area {
  margin-top: -100px;
  position: relative;
  z-index: 5;
  width: 536px;
  margin-left: 150px;
  @media #{$sm-layout} {
    margin-top: -130px;
    position: relative;
    z-index: 5;
    width: 536px;
    margin-left: 0;
    padding-right: 15px;
  }
  .accordion {
    .accordion-item {
      border: none;
      box-shadow: none;
      background: #fff;
      background-color: #fff;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 16px;
      border-radius: 6px;
      transition: none;
      .accordion-header {
        background: transparent;
        .accordion-button {
          border: none;
          box-shadow: none;
          font-size: 18px;
          font-weight: 600;
          color: #1f1f25;
          line-height: 28px;
          border-radius: 6px 6px 0 0;
          padding: 23px 22px;
          background: transparent;
          position: relative;
          &::after {
            background-image: none;
            content: "+";
            font-family: "fontAwesome";
            transform: none;
            top: 22px;
            position: absolute;
            right: 30px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #4aab3d1a;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--color-primary);
          }
          &[aria-expanded="true"] {
            &::after {
              content: "\f068";
            }
          }
        }
      }
      .accordion-body {
        padding: 0 22px 22px 22px;
      }
    }
  }
}

// hopping product area start end

.single-shopping-product {
  text-align: center;
  position: relative;
  transition: 0.3s;
  &:hover {
    .button-cart-area {
      .inner {
        transform: translateY(-28px);
      }
    }
  }
  .button-cart-area {
    height: 23px;
    overflow: hidden;
    .inner {
      transition: 0.3s;
    }
    .pricing-area {
      display: flex;
      align-items: center;
      gap: 20px;
      text-align: center;
      justify-content: center;
      transition: 0.3s;
      width: 100%;
      .active {
        font-weight: 600;
        line-height: 26px;
        color: #1f1f25;
      }
      .none {
        font-weight: 600;
        line-height: 26px;
        text-transform: capitalize;
        color: #74787c;
        position: relative;
        max-width: max-content;
        &::after {
          position: absolute;
          content: "";
          left: 0;
          width: 100%;
          height: 1px;
          background: #74787c;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .cart-btn {
      transition: 0.5s;
      i {
        color: #d01010;
      }
    }
  }
  &:hover {
    a.thumbnail {
      img {
        transform: scale(1.2);
      }
    }
  }
  a.thumbnail {
    overflow: hidden;
    position: relative;
    border-radius: 6px;
    display: block;
    img {
      width: 100%;
      transition: 0.5s;
    }
  }
  .inner-content {
    margin-top: 30px;
    .title {
      margin-bottom: 9px;
      transition: 0.3s;
      &:hover {
        color: var(--color-primary);
      }
    }
    a.cart-btn {
      color: #1f1f25;
      font-weight: 600;
      line-height: 16px;
      transition: 0.3s;
      i {
        margin-right: 8px;
      }
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  .invisible-btn {
    position: absolute;
    left: 50%;
    top: 35%;
    transition: 0.3s;
    transform: translateX(-50%) scale(0);
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
      li {
        margin: 0 5px;
        a {
          width: 40px;
          height: 40px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: 0.3s;
          i {
            color: var(--color-primary);
            transition: 0.3s;
          }
          &:hover {
            background: var(--color-primary);
            i {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

// single category arae start

.single-category {
  position: relative;
  max-width: 100%;
  .thumbnail {
    width: 100%;
    display: block;
    img {
      width: 100%;
    }
  }
  .single-category {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    text-align: center;
    .title {
      min-width: max-content;
      margin-bottom: 5px;
      color: #fff;
      text-align: center;
    }
    .arrow-btn-solari {
      display: flex;
      text-align: center;
      align-items: center;
      color: #fff;
      font-weight: 400;
      transition: 0.3s;
      &:hover {
        color: var(--color-primary);
      }
      i {
        margin-left: 5px;
      }
    }
  }
}

.banner-shop-content-wrapper {
  @media #{$large-mobile} {
    padding-left: 15px;
  }
}
.video-thumbnail-img {
  position: relative;
  .vedio-icone .video-play-button::after {
    background: #fff;
    border: none;
  }
  .vedio-icone .video-play-button::before {
    background: #ffffff44;
    border: none;
  }
}

// pagianmtion

.rts-elevate-pagination ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  li {
    margin: 0;
    margin-right: 10px;
    button {
      width: 50px;
      height: 50px;
      background: transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      border: 1px solid #e9e9e9;
      transition: 0.3s;
      &.active {
        background: var(--color-primary);
        color: #fff;
      }
      &:hover {
        background: var(--color-primary);
        color: #fff;
      }
    }
  }
}

.mySwiper-product-6 {
  overflow: hidden;
}

.single-project-area-start {
  position: relative;
  display: block;
  overflow: hidden;
  &:hover {
    .inner-content {
      bottom: 40px;
    }
  }
  .inner-content {
    position: absolute;
    bottom: -140px;
    left: 40px;
    transition: 0.5s;
    .tag {
      display: block;
      background: var(--color-primary);
      padding: 8px 15px;
      max-width: max-content;
      color: #fff;
    }
    a {
      display: block;
      padding: 14px 12px;
      color: #000;
      background: #fff;
      .title {
        margin-bottom: 0;
      }
    }
  }
}
//
.solar_energy {
  .title-wrapper-6-basis{
    .title{
      font-weight: 600;
    }
  }
  .swiper-pagination-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    & .swiper-pagination-bullet {
      height: 30px;
      width: 30px;
      border: 1px solid transparent;
      position: relative;
      background: transparent;
      opacity: 1;
      transition: all 0.3s ease-in;
      &::before {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: var(--color-primary);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &-active {
        border: 1px solid var(--color-primary);
      }
    }
  }
  .single-project-area-start {
    img {
      border-radius: 10px;
    }
  }
}
.case-style-8 {
  .single-case-ac {
    .thumbnail {
      border-radius: 0;
      img {
        min-height: 560px;
        max-height: 560px;
      }
    }
    .inner-content {
      border-radius: 0 !important;
      clip-path: polygon(30px 0%, 100% 0%, 92% 100%, 0% 100%);
      opacity: 1 !important;
      bottom: 30px !important;
      .inner {
        left: 60px !important;
        h5 {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .swiper-pagination-h2-c {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    & .swiper-pagination-bullet {
      height: 30px;
      width: 30px;
      border: 1px solid transparent;
      position: relative;
      background: transparent;
      opacity: 1;
      transition: all 0.3s ease-in;
      &::before {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: #bbbbbb;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &-active {
        border: 1px solid var(--color-primary);
        position: relative;
        &::before {
          position: absolute;
          content: "";
          background: var(--color-primary);
        }
      }
    }
  }
}
.rts-product-area-2 {
  .title-wrapper-6-basis {
    .title-area {
      span {
        color: var(--color-white);
        font-weight: 600;
        font-family: var(--font-secondary);
        display: inline-block;
        margin-bottom: 10px;
      }
      .title {
        color: var(--color-white);
        font-family: var(--font-secondary);
        font-weight: 600;
      }
    }
    .button-area-start {
      p {
        color: var(--color-white);
      }
      a {
        color: var(--color-white);
        i {
          border-color: var(--color-white);
        }
        &:hover {
          i {
            background: transparent;
            border: 1px solid var(--color-white);
            color: #fff;
          }
        }
      }
    }
  }
  .swiper-pagination-9{
    max-width: max-content;
    margin-top: 60px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .swiper-pagination-bullet{
      background: transparent;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid transparent;
      position: relative;
      opacity: 1;
      &::before{
        position: absolute;
        content: '';
        height: 6px;
        width: 6px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        background: var(--color-white);
        transform: translate(-50%, -50%);
      }
      &-active{
        border-color: var(--color-white);
      }
    }
  }
  .single-project-area-start .inner-content .tag{
    background: #ff7029;
  }
}
